import dynamic from 'next/dynamic';

const ContactUsPage = dynamic(() => import('./contact-us'));
const NotFound = dynamic(() => import('@layouts/NotFound/index'));

const templates = (name: string) => {
  switch (name) {
    case 'ContactUsTemplate':
      return ContactUsPage;
    default:
      return NotFound;
  }
};

export default templates;
