/* eslint-disable @typescript-eslint/no-explicit-any */

/*
  dynamic generated pages by marketing team using strapi
  type: SSG
*/
import type { GetServerSideProps } from 'next';
import { ParsedUrlQuery } from 'querystring';
import * as GetPageProps from '@templates/serverSideProps';
import { PagesProps } from '@templates/pagesInterface';
import { getLanguage } from '@foxtons/fdl/utils/index';
import { useUserFromStorage } from '@foxtons/fdl/utils/customHooks/useUserFromStorage';
import {
  getMetaTags,
  parseBreadcrumbsProps,
  renameKeys,
  toPascalCase
} from '@utils/helperFunctions';
import * as parserFunction from '@utils/contentParsers';
import getSections from '@templates/components';

import templatesTesting from '@templates/index';
import dynamic from 'next/dynamic';
import Box from '@mui/material/Box';
import {
  getPageContent,
  getPropertyByMarketingId,
  fetchMegaMenu,
  fetchFooter
} from '@utils/httpRequestConfigs/httpRequests';
import { useEffect, useMemo, useState } from 'react';
import ComponentWrapper from '@components/ComponentWrapper';
import useClientOnly from '@utils/customHooks/ClientOnly';
import JsonLdTags, { JsonLdType } from '@layouts/JsonLdTags';
import MetaTags from '@foxtons/fdl/components/MetaTags/index';

const HeroBanner = dynamic(
  () => import('@foxtons/fdl/layouts/HeroBanner/index')
);
const FoxtonsLoader = dynamic(
  () => import('@foxtons/fdl/components/FoxtonsLoader/index'),
  { ssr: false }
);

interface DynamicPageProps {
  template: string;
  page: PagesProps;
  sections: any[];
  enSections: any[];
  cnSections: any[];
  seo?: any;
  states: { [x: string]: boolean };
  jsonLd?: JsonLdType;
}

/**
 * strapi:
 * chinese/language hero banner | LanguageHeroBanner
 * button:
 * variant: switch-lang-cn | switch-lang-en
 * href: empty
 *
 * frontend
 * Button component or in LanguageHeroBanner component
 * if button variant is switch-lang-cn apply styles and send onclick handler with setLanguage(switchLang(button.variant)) // switchLang would return en, cn etc by default en
 * [[...slug]] listen setLanguage event trigger (window storage event)
 * setActiveSection according to value in storage and then clear storage
 *
 */

const emptyList: any[] = [];

export default function DynamicPage({
  template,
  page,
  sections,
  seo,
  states,
  cnSections = emptyList,
  enSections = emptyList,
  jsonLd
}: DynamicPageProps) {
  const [user] = useUserFromStorage();
  const { hasMounted } = useClientOnly();
  const [componentStates, setComponentStates] = useState(states); //states for modal/drawers
  const [language, setLanguage] = useState('en');

  // if handle close is triggered
  useEffect(() => {
    const listenStorage = () => {
      const pageLanguage = getLanguage();

      if (pageLanguage) {
        setLanguage(pageLanguage);
        sessionStorage.removeItem('foxtonsLanguage'); // empty session
      }

      if (Object.keys(componentStates).length) {
        const sessionStates = sessionStorage.getItem('foxtonsComponentStates');
        const stateObj = JSON.parse(sessionStates || '{}');

        if (stateObj) {
          setComponentStates((prev) => ({
            ...prev,
            [stateObj?.key]: stateObj.value
          }));

          sessionStorage.removeItem('foxtonsComponentStates');
        }
      }
    };

    window.addEventListener('storage', listenStorage);

    return () => window.removeEventListener('storage', listenStorage);
  }, [componentStates, cnSections, enSections, sections]);

  const activeSections = useMemo(() => {
    if (language === 'en') {
      return enSections?.length ? enSections : sections; //set english section pages
    }

    if (language === 'cn') {
      return cnSections?.length ? cnSections : sections; //set chinese section pages
    }

    return sections;
  }, [cnSections, enSections, language, sections]);

  // sync component states with states
  useEffect(() => {
    setComponentStates(states);
  }, [states]);

  // if server side is running the logic
  if (!template) {
    return <FoxtonsLoader hasBackdrop open />;
  }

  // if template is StandardTemplate then return sections based on new architecture
  if (template === process.env.NEXT_PUBLIC_DYNAMIC_TEMPLATE_NAME) {
    if (!activeSections.length) {
      return <Box>No Sections Found</Box>;
    }

    return (
      <>
        {seo && <MetaTags {...getMetaTags(seo)} />}
        <JsonLdTags jsonLd={jsonLd} />

        {activeSections.map((item, index) => {
          const DynamicSection = getSections(item.component);

          if (DynamicSection) {
            if (item.component === 'HeroBanner') {
              return (
                <Box key={item?.id}>
                  <HeroBanner
                    {...item}
                    key={item?.id}
                    token={
                      item.component === 'HeroBanner'
                        ? Boolean(user?.isLoggedIn)
                        : undefined
                    }
                  />
                </Box>
              );
            }

            if (item.component === 'HorizontalScroll') {
              return (
                <DynamicSection {...item} key={item?.id} scrollDuration={0} />
              );
            }

            if (item.component === 'GetAhead') {
              return (
                <DynamicSection {...item} key={item?.id} sectionIndex={index} />
              );
            }

            if (item?.hasState && hasMounted) {
              return (
                <ComponentWrapper
                  otherProps={{
                    componentStates: componentStates[item.component],
                    hasMounted
                  }}
                >
                  <DynamicSection
                    {...item}
                    key={item?.id}
                    open={componentStates[item.component]}
                    handleClose={() =>
                      setComponentStates((prev) => ({
                        ...prev,
                        [item.component]: false
                      }))
                    }
                  />
                </ComponentWrapper>
              );
            }

            return <DynamicSection {...item} key={item?.id} />;
          }

          return <>Not found</>;
        })}
      </>
    );
  }

  // if template is NOT StandardTemplate then return template based pages - old architecture
  const renderTemplate = () => {
    const TemplateComponent = templatesTesting(template);

    return <TemplateComponent {...page} />;
  };

  return <div>{renderTemplate()}</div>;
}

interface Params extends ParsedUrlQuery {
  slug: string[];
}

// res arg for redirection
//if there is any link that would open drawer/modal
const getStates = (str: string) => {
  const buttonOrLinkButtonComponentStatesRegex =
    /"href":"component:\w+(\/[\w_]+)?"/gm;
  const richTextLinkComponentStatesRegex =
    /\[(\w)+(\s+\w+)*(\w)+\]\(component:\w+(\/[\w_]+)?\)/gm;
  const hyperlinks = (str || '').match(richTextLinkComponentStatesRegex) || [];
  const buttonLinks =
    (str || '').match(buttonOrLinkButtonComponentStatesRegex) || [];
  const states = [...hyperlinks, ...buttonLinks]?.map(
    (item) =>
      item
        .replace(/\[(\w)+(\s)*(\w)+\]\(component:|\)|"href":|"|component:/g, '')
        .split('/')[0]
  );

  return states;
};

const localizedSections = async (inputArr: any[]) => {
  const sections = [];

  for (let i = 0; i < inputArr.length; i++) {
    const element = inputArr[i];
    const componentName = toPascalCase(
      element.component.slice(
        element.component.indexOf('.') + 1,
        element.component.length
      )
    );
    const sectionData = await parserFunction[
      componentName as keyof typeof parserFunction
    ]?.(element);

    // transformation of sections content from strapi into sections props
    if (sectionData) {
      sections.push(sectionData);
    }
  }

  return { sectionsArr: sections };
};

export const getServerSideProps: GetServerSideProps = async ({
  params,
  preview,
  ...ctx
}) => {
  try {
    // hit api to get strapi endpoint and then hit api endpoint to get page content and template information
    // if slug===undefined then its landing page
    const { slug } = params as Params;

    const url = slug === undefined ? '/' : slug?.join('/');

    const isNumericOnlyUrl = /^(\d+)$/.test(slug?.[0] || '');

    if (isNumericOnlyUrl) {
      const { success, response, error } = await getPropertyByMarketingId(
        slug[0]
      );

      if (success && response?.data) {
        const { instructionType, postcodeShort, propertyReference } =
          response.data;

        if (instructionType && postcodeShort && propertyReference) {
          const propertyListPagePathConstantModule = await import(
            '@foxtons/fdl/utils/Constants/propertyListPagePathConstant'
          );
          const {
            propertiesForSale,
            propertiesForLongLet,
            propertiesForShortLet
          } = propertyListPagePathConstantModule.default;

          let instructionTypeSlug;

          if (instructionType === 'sale') {
            instructionTypeSlug = propertiesForSale;
          } else if (instructionType === 'letting') {
            instructionTypeSlug = propertiesForLongLet;
          } else if (
            instructionType === 'short_letting' ||
            instructionType === 'event'
          ) {
            instructionTypeSlug = propertiesForShortLet;
          } else {
            throw new Error(
              'getPropertyByMarketingId failed to match instruction type'
            );
          }

          const propertyUrl = `${instructionTypeSlug}/${postcodeShort}/${propertyReference}`;
          return {
            redirect: {
              destination: propertyUrl,
              statusCode: 301
            }
          };
        }
      }

      throw new Error('getPropertyByMarketingId failed: ', error);
    }

    const [headerProps, footerProps, res] = await Promise.all([
      fetchMegaMenu(),
      fetchFooter(),
      // TODO: move api endpoint into API_ENDPOINTS
      getPageContent(
        `/api/${
          preview ? 'get-draft-content' : 'get-strapi-content'
        }?pageUrl=${url}`
      )
    ]);

    const pageResponse = res?.response;

    // No data found
    if (!pageResponse?.data?.data && !pageResponse?.data?.header) {
      if (preview) {
        return {
          props: {
            template: 'NotFound',
            seo: null,
            page: {},
            headerProps,
            footerProps,
            states: {},
            sections: [],
            enSections: [],
            cnSections: [],
            jsonLd: null
          }
        };
      }

      return {
        notFound: true
      };
    }

    // redirect found
    if (pageResponse?.data?.header) {
      const redirectHeaders = renameKeys(pageResponse?.data?.header);
      const targetUrl = redirectHeaders?.targeturl;
      const isPermanentRedirect =
        redirectHeaders?.redirectType === 'Permanent (301)';

      return {
        redirect: {
          destination: targetUrl ? `/${targetUrl}` : '',
          statusCode: isPermanentRedirect ? 301 : 302
        }
      };
    }

    const result = pageResponse?.data?.data?.attributes;

    const template = result
      ? process.env.NEXT_PUBLIC_DYNAMIC_TEMPLATE_NAME
      : 'OLD_ARCH';

    const processedData = renameKeys(pageResponse?.data);

    // if template is StandardTemplate then return sections based on new architecture
    if (template === process.env.NEXT_PUBLIC_DYNAMIC_TEMPLATE_NAME) {
      const sections: any[] = [];
      const states: { [x: string]: boolean } = {};
      const processedResult = renameKeys(result); // convert to camelCase
      const localizedResults = processedData?.localizations || {}; // convert to camelCase

      const statesArr = getStates(JSON.stringify(processedResult || {})); // if there is any link in the richText that would open drawer/modal
      const statesLocalizationArr = getStates(JSON.stringify(localizedResults)); // if there is any link in the richText that would open drawer/modal

      if (statesArr) {
        statesArr.forEach((item, idx) => {
          if (statesArr.indexOf(item) === idx) {
            states[item] = false;

            // add cookie preferences modal component
            if (item === 'CookiePreferencesModal') {
              processedResult?.body?.push({ component: item });
            }
          }
        });
      }

      if (statesLocalizationArr) {
        statesLocalizationArr.forEach((item, idx) => {
          if (statesLocalizationArr.indexOf(item) === idx) {
            states[item] = false;

            // add cookie preferences modal component
            if (item === 'CookiePreferencesModal') {
              if (localizedResults?.en?.length) {
                localizedResults?.en?.push({ component: item });
              }

              if (localizedResults?.zh?.length) {
                localizedResults?.zh?.push({ component: item });
              }
            }
          }
        });
      }

      const seo = processedResult?.seo;

      const metaUrl =
        seo?.url ||
        `${process.env.NEXT_PUBLIC_STATIC_ASSET_URL}/${url === '/' ? '' : url}`;

      seo.url = metaUrl;

      const breadcrumbsProps = parseBreadcrumbsProps({
        breadcrumbs: processedResult?.breadcrumbs,
        url: processedResult?.url
      });

      for (let i = 0; i < processedResult.body.length; i++) {
        const element = processedResult.body[i];
        const componentName = toPascalCase(
          element.component.slice(
            element.component.indexOf('.') + 1,
            element.component.length
          )
        );

        const sectionData = await parserFunction[
          componentName as keyof typeof parserFunction
        ]?.(element);

        // transformation of sections content from strapi into sections props
        if (sectionData) {
          sections.push({
            ...sectionData,
            publishedAt: processedResult?.publishedAt || ''
          });
        } else {
          console.error(
            `This '${componentName}' component has no matching content parser in contentParsers.ts [${element.component}]. `
          );
        }
      }

      const [enArr, cnArr] = await Promise.all([
        await localizedSections(localizedResults?.en || []),
        await localizedSections(localizedResults?.zh || [])
      ]);

      const enSections = enArr.sectionsArr;
      const cnSections = cnArr.sectionsArr;

      return {
        props: {
          template,
          seo,
          page: {},
          headerProps,
          footerProps,
          breadcrumbsProps,
          states,
          sections,
          enSections,
          cnSections,
          jsonLd: result?.jsonld || null
        }
      };
    }

    // if template is NOT StandardTemplate then return template based pages - old architecture
    if (!GetPageProps[template as keyof typeof GetPageProps]) {
      return {
        props: {
          template: 'NotFound',
          page: {},
          sections: [],
          seo: {},
          headerProps
        }
      };
    }

    const pageContent =
      GetPageProps[template as keyof typeof GetPageProps](result);

    const metaUrl =
      pageContent.meta.url ||
      `${process.env.NEXT_PUBLIC_STATIC_ASSET_URL}/${url === '/' ? '' : url}`;

    pageContent.meta.url = metaUrl;

    return {
      props: {
        template,
        page: pageContent,
        sections: [],
        seo: {},
        headerProps,
        footerProps,
        jsonLd: result?.jsonld || null
      }
    };
  } catch (error: unknown) {
    console.error(
      'Error in getServerSideProps (apps/fxweb/src/pages/[[...slug]].tsx):',
      error
    );

    ctx.res.setHeader('Cache-Control', 'no-cache, no-store, must-revalidate');

    return {
      redirect: {
        destination: '/500',
        statusCode: 302
      }
    };
  }
};
