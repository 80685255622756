import Head from 'next/head';
import { useRouter } from 'next/router';

export type JsonLdType = Record<string, any>;

interface JsonLdProps {
  jsonLd?: JsonLdType[] | JsonLdType;
}

const getJsonLdScriptTag = (item) => {
  const router = useRouter();
  const baseUrl = process.env.NEXT_PUBLIC_URL + router.asPath;

  let data = item;

  if (!data?.url && data['@type'] !== 'WebPage' && data?.url !== '') {
    data.url = baseUrl;
  }

  if (data?.url === '') {
    const { url, ...rest } = data;

    data = rest;
  }

  return (
    <script
      key={`${data['@type']}${data.url}`}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};

export default function JsonLdTags({ jsonLd }: JsonLdProps) {
  return (
    <>
      {/* If object is provided get one tag for array loop through items */}
      {jsonLd && !Array.isArray(jsonLd) ? (
        <Head>{getJsonLdScriptTag(jsonLd)}</Head>
      ) : (
        <Head>{jsonLd && jsonLd.map((item) => getJsonLdScriptTag(item))}</Head>
      )}
    </>
  );
}
